<template>
  <div>
    <!--  门派联赛时显示  -->
    <!-- 门派名字 -->
    <h4
      style="margin: 15px 0 20px 130px; font-size: 20px">
      <span>{{ titleName }}·{{ topTitleType }}</span>
    </h4>
    <el-form
      v-loading="loading"
      label-width="200px"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      class="demo-ruleForm"
    >
      <!-- 新增赛段 -->
      <!-- <el-form-item
        :label="matchContent"
        style="margin: 35px, 0"
        v-if="matchContent  "
      >
        <el-col :span="8">
          <span style="font-size: 20px"> · </span>
          <span style="font-size: 20px">新增赛段</span>
        </el-col>
      </el-form-item> -->
      <!-- 赛段名称 -->
      <el-form-item
        label="赛段名称"
        prop="match_name"
        label-width="200px"
        style="margin-bottom: 35px"
      >
        <!-- 编辑时不能改 -->
        <el-col :span="6">
          <el-select
            v-model="dataForm.type_id"
            value-key="typeId"
            style="width: 200px"
            :disabled="type_id != undefined"
            @change="getClearMatchNames"
          >
            <el-option
              v-for="(value, key) in optionsMatch"
              :key="key"
              :label="value"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-form-item>
      <match_segment_qualifier
        v-if="dataForm.type_id === 'qualifier'"
        :match_type="matchType"
        :match_id="match_id"
        :season_id="season_id"
        :stage_id="stage_id"
        :view_type="view_type"
      ></match_segment_qualifier>
      <div v-if="dataForm.type_id !== 'qualifier'">
        <!-- 预约赛事送好礼  dataForm.type_id == 'audition -->
        <div v-if="dataForm.type_id === 'audition'">
          <el-form-item label="预约赛事送好礼" required label-width="200px">
            <el-col :span="5">
              <el-form-item
                prop="match_time.reservationGift_startTime"
                id="reservationGift_startTime"
              >
                <el-date-picker
                  ref="reservationGift_startTime"
                  type="datetime"
                  placeholder="选择预约赛事送好礼开始时间"
                  v-model="dataForm.match_time.reservationGift_startTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="reservationGift_endTime">
              <el-form-item prop="match_time.reservationGift_endTime">
                <el-date-picker
                  ref="reservationGift_endTime"
                  type="datetime"
                  placeholder="选择预约赛事送好礼结束时间"
                  v-model="dataForm.match_time.reservationGift_endTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!-- 报名时间  dataForm.type_id == 'audition'-->
        <div v-if="dataForm.type_id === 'audition'">
          <el-form-item label="报名时间" required label-width="200px">
            <el-col :span="5">
              <el-form-item prop="match_time.sign_up_start" id="sign_up_start">
                <el-date-picker
                  ref="sign_up_start"
                  type="datetime"
                  placeholder="选择报名开始时间"
                  v-model="dataForm.match_time.sign_up_start"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="sign_up_end">
              <el-form-item prop="match_time.sign_up_end">
                <el-date-picker
                  ref="sign_up_end"
                  type="datetime"
                  placeholder="选择报名结束时间"
                  v-model="dataForm.match_time.sign_up_end"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!-- 复活榜时间 dataForm.type_id == 'resurrection'-->
        <div v-if="dataForm.type_id === 'resurrection'">
          <el-form-item label="复活榜时间" required label-width="200px">
            <el-col :span="5">
              <el-form-item
                prop="match_time.revive_startTime"
                id="revive_startTime"
              >
                <el-date-picker
                  ref="revive_startTime"
                  type="datetime"
                  placeholder="选择复活榜开始时间"
                  v-model="dataForm.match_time.revive_startTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="revive_endTime">
              <el-form-item prop="match_time.revive_endTime">
                <el-date-picker
                  ref="revive_endTime"
                  type="datetime"
                  placeholder="选择复活榜结束时间"
                  v-model="dataForm.match_time.revive_endTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!--  队伍管理时间 -->
        <div
          v-if="
            dataForm.type_id === 'elimination1' ||
            dataForm.type_id === 'elimination2' ||
            dataForm.type_id === 'resurrection'
          "
        >
          <el-form-item
            label="队伍管理时间"
            :required="dataForm.type_id != 'wpl_finals'"
            label-width="200px"
          >
            <el-col :span="5">
              <el-form-item
                :prop="
                  dataForm.type_id != 'wpl_finals'
                    ? 'match_time.team_startTime'
                    : 'team_team'
                "
                id="team_startTime"
              >
                <el-date-picker
                  ref="team_startTime"
                  type="datetime"
                  placeholder="选择队伍管理开始时间"
                  v-model="dataForm.match_time.team_startTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="team_endTime">
              <!-- prop="match_time.team_endTime"  -->
              <el-form-item
                :prop="
                  dataForm.type_id != 'wpl_finals'
                    ? 'match_time.team_endTime'
                    : 'team_team'
                "
              >
                <el-date-picker
                  ref="team_endTime"
                  type="datetime"
                  placeholder="选择队伍管理结束时间"
                  v-model="dataForm.match_time.team_endTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!-- 赛前分组时间 -->
        <div>
          <el-form-item
            v-if="
              dataForm.type_id === 'final' ||
              (matchType.includes('wpl') && dataForm.type_id !== 'audition')|| (dataForm.type_id === 'regular' && matchType !== 'match_s4' && matchType !== 'match_s5' )
            "
            label="赛前分组时间"
            :required="dataForm.type_id !== 'wpl_finals'"
            label-width="200px"
          >
            <el-col :span="5">
              <el-form-item
                :prop="
                  dataForm.type_id != 'wpl_finals'
                    ? 'match_time.pre_startTime'
                    : 'team_team'
                "
                id="match_time.pre_startTime"
              >
                <el-date-picker
                  ref="pre_startTime"
                  type="datetime"
                  placeholder="选择赛前分组开始时间"
                  v-model="dataForm.match_time.pre_startTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="pre_endTime">
              <el-form-item
                id="match_time.pre_endTime"
                :prop="
                  dataForm.type_id != 'wpl_finals'
                    ? 'match_time.pre_endTime'
                    : 'team_team'
                "
              >
                <el-date-picker
                  ref="pre_endTime"
                  type="datetime"
                  placeholder="选择赛前分组结束时间"
                  v-model="dataForm.match_time.pre_endTime"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!-- 淘汰赛参赛资格确定时间 -->
        <div v-if="dataForm.type_id === 'audition'">
          <el-form-item
              label="淘汰赛参赛资格确定时间"
              required
              label-width="200px"
              prop="match_time.elimination_time"
              id="elimination_time">
              <el-col :span="5">
                <el-date-picker
                    ref="elimination_time"
                    type="datetime"
                    placeholder="选择淘汰赛参赛资格确定时间"
                    v-model="dataForm.match_time.elimination_time"
                    style="width: 100%">
                </el-date-picker>
              </el-col>
          </el-form-item>
        </div>
        <!-- 比赛时间 -->
        <div v-if="isMatch">
          <el-form-item label="比赛时间" required label-width="200px">
            <el-col :span="5">
              <el-form-item
                prop="match_time.match_start"
                id="match_time.match_start"
              >
                <el-date-picker
                  ref="match_start"
                  type="datetime"
                  placeholder="选择比赛开始时间"
                  v-model="dataForm.match_time.match_start"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="1" style="text-align: center">-</el-col>
            <el-col :span="5" id="match_end">
              <el-form-item
                prop="match_time.match_end"
                id="match_time.match_end"
              >
                <el-date-picker
                  ref="match_end"
                  type="datetime"
                  placeholder="选择比赛结束时间"
                  v-model="dataForm.match_time.match_end"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
        </div>
        <!-- 每组微信二维码 -->
        <el-form-item
          v-if="dataForm.qr_code.length !== 0"
          label=""
          id="qr_code"
          label-width="0px"
          prop="qr_code"
          required
        >
          <el-col
            :span="8"
            v-for="(item, index) in dataForm.qr_code"
            :key="item.group"
          >
            <el-form-item
              :label="`${item.group}组微信二维码`"
              :prop="`qr_code[${index}].code_picture`"
              :rules="
                dataForm.type_id === 'wpl_finals'
                  ? rules.code_picture_no
                  : rules.code_picture
              "
            >
              <el-upload
                class="avatar-uploader"
                :show-file-list="false"
                :before-upload="beforeTopImageUpload"
                action="#"
                drag
                :http-request="httpUploadRequest"
                :on-success="handleSuccess(item.group)"
              >
                <img
                  v-if="item.code_picture"
                  :src="item.code_picture"
                  class="avatar"
                />
                <div v-else>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    拖到此处，或<em>点击上传</em>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-form-item>
        <!-- 晋级常规赛名额，每组晋级名额 -->
        <el-row v-if="dataForm.type_id === 'regular'">
          <el-col :span="7">
            <el-form-item
              label="晋级总决赛名额"
              prop="qualifiers"
              required
              label-width="200px"
            >
              <el-input
                v-model="dataForm.qualifiers"
                style="width: 200px; margin-right: 20px"
              >
                <template slot="append"> 个 </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              v-if="dataForm.matchType === 'match_s3'"
              label="每组晋级名额"
              prop="groupQualifiers"
              required
              label-dataForm="200px"
            >
              <el-input
                v-model="dataForm.groupQualifiers"
                style="width: 200px; margin-right: 20px"
              >
                <template slot="append"> 个 </template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- :label="`${optionsMatch[dataForm.type_id]}规则`" -->
        <el-form-item
          label="规则"
          label-width="200px"
          prop="rule"
          id="quillEditorQiniuPrize"
        >
          <!-- 基于elementUi的上传组件 el-upload begin-->
          <el-upload
            class="avatar-uploader"
            :accept="'image/*'"
            :show-file-list="false"
            action="#"
            style="height: 0"
            :http-request="httpUploadEditorRequest"
          >
          </el-upload>
          <!-- 基于elementUi的上传组件 el-upload end-->
          <quill-editor
            class="editor ql-editor"
            v-model="dataForm.rule"
            ref="customQuillEditorPrize"
            :options="editorOptionPrize"
          >
          </quill-editor>
        </el-form-item>
        <!-- 赛事微信二维码-->
        <el-form-item
          v-if="
            dataForm.type_id === 'elimination1' ||
            dataForm.type_id === 'elimination2' ||
            dataForm.type_id === 'resurrection'
            "
          label="赛事微信二维码"
          prop="officialWechatQr"
          required>
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeTopImageUpload"
            action="#"
            drag
            :http-request="httpOfficialWechatUploadRequest"
          >
            <img
                v-if="dataForm.officialWechatQr"
                :src="dataForm.officialWechatQr"
                class="avatar"
            />
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">拖到此处，或<em>点击上传</em></div>
            </div>
          </el-upload>
          [请务必上传微信二维码，否则无法识别]
        </el-form-item>
        <!-- 跳转链接 -->
        <el-form-item
          id="match_website_links"
          label-width="200px"
          label="小程序跳转链接"
          prop="match_website_links"
          style="margin-bottom: 35px"
        >
          <el-col :span="12">
            <div class="link-tip-wraper">
              <el-input
                ref="match_news_title"
                v-model="dataForm.link"
                placeholder="使用官网链接需删除'.163'"
              ></el-input>
              <div class="link-tip">(注意:使用官网链接需删除'.163')</div>
            </div>
          </el-col>
        </el-form-item>
        <el-form-item
          id="match_news_links"
          label-width="200px"
          label="H5跳转链接"
          prop="match_news_links"
          style="margin-bottom: 35px"
        >
          <el-col :span="12">
            <el-input
              ref="match_news_title"
              v-model="dataForm.link_h5"
              placeholder="请输入H5跳转链接"
            ></el-input>
          </el-col>
        </el-form-item>
        <!-- 提交 -->
        <el-form-item style="margin-top: 50px">
          <perButton
            type="primary"
            perm="armory:reporter:save"
            @click="submitForm()"
            size="large"
            >提交</perButton
          >
          <el-button size="large" @click="goBack()">取消</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import perButton from "@/components/perm/perButton";
import match_segment_qualifier from "./components/manager_qualifiter/match_segment_qualifier.vue";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { toolbarOptions ,matchTypeMap } from "./public.js/toolbarOptions";
import { mapActions, mapState } from "vuex";
export default {
  name: "manager",
  components: {
    perButton,
    match_segment_qualifier,
  },
  data() {
    let self = this;
    const PrematchGroupingTime = (rule, value, callback) => {
      if (
        self.dataForm.match_time.pre_startTime !== "" &&
        self.dataForm.match_time.pre_endTime !== ""
      ) {
        let sign_up_start = new Date(self.dataForm.match_time.pre_startTime);
        let sign_up_end = new Date(self.dataForm.match_time.pre_endTime);
        if (sign_up_end <= sign_up_start) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const playingTime = (rule, value, callback) => {
      if (
        self.dataForm.match_time.match_start !== "" &&
        self.dataForm.match_time.match_end !== ""
      ) {
        let sign_up_start = new Date(self.dataForm.match_time.match_start);
        let sign_up_end = new Date(self.dataForm.match_time.match_end);
        if (sign_up_end <= sign_up_start) {
          callback(new Error("结束时间必须大于开始时间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      matchType: "", //赛事类型
      matchTypesContent: {
        match_s1: "门派联赛第一赛季",
        match_s2: "门派联赛第二赛季",
        match_s3: "门派联赛第三赛季",
      },
      elimination1: [
        { group: 1, code_picture: "" },
        { group: 2, code_picture: "" },
        { group: 3, code_picture: "" },
        { group: 4, code_picture: "" },
        { group: 5, code_picture: "" },
        { group: 6, code_picture: "" },
      ],
      elimination2: [
        { group: 1, code_picture: "" },
        { group: 2, code_picture: "" },
        { group: 3, code_picture: "" },
      ],
      resurrection: [{ group: 1, code_picture: "" }],
      isMatch: true,
      match_id: "",
      season_id: "",
      stage_id: "",
      type_id: "",
      optionsMatch: [],
      match_data: {},
      match_type: "",
      match_subject_id: "",
      view_type: "",
      type: "s1",
      has_stage: false,
      org_rule: "",
      org_score: "",
      org_prize: "",
      dataForm: {
        groupQualifiers: "",
        qualifiers: "",
        qr_code: [{ group: 1, code_picture: "" }],
        _id: "",
        type_id: "",
        rule: "",
        rule_url: "",
        sign_rule: "",
        sign_rule_url: "",
        team_rule: "",
        team_rule_url: "",
        resurrection_rule: "",
        resurrection_rule_url: "",
        officialWechatQr: "",
        link_h5: "",
        link: "",
        match_time: {
          reservationGift_startTime: "",
          reservationGift_endTime: "",
          sign_up_start: "",
          sign_up_end: "",
          revive_startTime: "",
          revive_endTime: "",
          team_startTime: "",
          team_endTime: "",
          pre_startTime: "",
          pre_endTime: "",
          match_start: "",
          match_end: "",
          elimination_time: '',
        },
      },

      rules: {
        groupQualifiers: [
          {
            required: true,
            message: "请填写每组晋级名额",
            trigger: ["blur", "change"],
          },
        ],
        qualifiers: [
          {
            required: true,
            message: "请填写晋级常规赛名额",
            trigger: ["blur", "change"],
          },
        ],
        code_picture: [
          {
            required: true,
            message: "请选择二维码图",
            trigger: ["change", "blur"],
          },
        ],
        code_picture_no: [
          {
            required: false,
            trigger: ["change", "blur"],
          },
        ],
        qr_code: [
          {
            required: true,
            message: "请选择二维码图",
            trigger: ["change", "blur"],
          },
        ],
        "match_time.reservationGift_startTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
        ],
        "match_time.reservationGift_endTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
        ],
        "match_time.sign_up_start": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        "match_time.sign_up_end": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        "match_time.revive_startTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        "match_time.revive_endTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        "match_time.team_startTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        team_team: [
          {
            required: false,
            trigger: ["blur"],
          },
        ],
        "match_time.team_endTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
        ],
        "match_time.pre_startTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
          { validator: PrematchGroupingTime, trigger: ["change", "blur"] },
        ],
        "match_time.pre_endTime": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur"],
          },
          { validator: PrematchGroupingTime, trigger: ["change", "blur"] },
        ],
        "match_time.match_start": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
          { validator: playingTime, trigger: ["change", "blur"] },
        ],
        "match_time.match_end": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
          { validator: playingTime, trigger: ["change", "blur"] },
        ],
        "match_time.elimination_time": [
          {
            required: true,
            message: "请选择日期",
            trigger: ["blur", "change"],
          },
        ],
        rule: [
          {
            required: true,
            message: "请输入积分规则",
            trigger: ["blur", "change"],
          },
        ],
      },
      editorOptionPrize: {
        placeholder: "请填写奖励",
        modules: {
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          toolbar: {
            container: toolbarOptions, // 工具栏
            handlers: {
              size: function (value) {
                if (value === "custom-size") {
                  value = prompt("请输入字体大小");
                  if (
                    !/^(([2-6][0-9])|([7][0-2])|([1][8-9]))(rpx)$/.test(value)
                  ) {
                    alert(
                      "请输入合法的字体大小，使用rpx单位， 大小在18rpx-72rpx之间, 1rpx=2px， 用于适配不同设备"
                    );
                    value = "";
                  }
                }

                this.quill.format("size", value);
              },
              color: function (value) {
                if (value === "custom-color") {
                  value = prompt("请输入十六进制的颜色");
                  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) {
                    alert("请输入合法的十六进制颜色");
                    value = "";
                  }
                }

                this.quill.format("color", value);
              },
              image: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  document
                    .querySelector(
                      "#quillEditorQiniuPrize .avatar-uploader input"
                    )
                    .click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapState("match", ["match_types"]),
    isEdit: function () {
      if (this.view_type === "edit") {
        return true;
      }

      return false;
    },
    matchContent() {
      return this.matchTypesContent[this.matchType] || "";
    },
    topTitle() {
      if (this.view_type === "edit") {
        return "edit";
      }
      return "add";
    },
    titleName() {
      return matchTypeMap[this.matchType] || "默认标题";
    },
    topTitleType() {
      if (this.view_type === "edit") {
        return "编辑赛段";
      }
      return "新增赛段";
    },
    titletype() {
      const matchType = {
        qualifier: "预选赛",
        regular: "常规赛",
        final: "总决赛",
      };
      return matchType[this.type_id] || "";
    },
  },
  async created() {
    this.matchType = this.$route.query.match_type;
  },
  async mounted() {
    //赛事id
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;
    //赛段
    this.view_type = this.$route.query.view_type;
    this.stage_id = this.$route.query._id;
    this.type_id = this.$route.query.type_id;
    // 获取页面数据
    if (this.stage_id !== undefined) {
      let res = await this.$http.match_subject.match_detail_stage(
        this.stage_id
      );
      console.log(res.data);
      if (res.code === 0) {
        this.match_data = res.data;
      }
    }
    //获取下拉框
    this.getMatchNames();

    this.setFormData();
  },

  methods: {
    getUploadRules(index) {
      return [
        {
          required: true,
          message: "Please upload an image",
          trigger: `upload${index}`,
        },
      ];
    },
    getClearMatchNames() {
      //二维码更换
      //二维码赋值
      if (this.dataForm.type_id === "elimination1") {
        this.dataForm.qr_code = this.elimination1;
      } else if (this.dataForm.type_id === "elimination2") {
        this.dataForm.qr_code = this.elimination2;
      } else if (this.dataForm.type_id === "resurrection") {
        this.dataForm.qr_code = this.resurrection;
      } else {
        this.dataForm.qr_code = [];
      }
      //  else if (this.dataForm.type_id == "wpl_finals") {
      //   this.dataForm.qr_code = this.resurrection;
      // }

      this.dataForm.match_time = {
        reservationGift_startTime: "",
        reservationGift_endTime: "",
        sign_up_start: "",
        sign_up_end: "",
        revive_startTime: "",
        revive_endTime: "",
        team_startTime: "",
        team_endTime: "",
        pre_startTime: "",
        pre_endTime: "",
        match_start: "",
        match_end: "",
        elimination_time: '',
      };
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    // 获取下拉框所有赛段
    async getMatchNames() {
      const { data } = await this.$http.match_subject.match_list_stages_names({
        // type_id: this.type_id,
        type_id: this.matchType,
      });
      console.log("optionsMatch", data);
      this.optionsMatch = data;
    },
    getDateTime(dataStr) {
      if (dataStr != null) {
        return new Date(dataStr);
      }

      return null;
    },
    //如果没拿到数据。则代表为新建
    setFormData() {
      if (
        JSON.stringify(this.match_data) !== "{}" &&
        JSON.stringify(this.match_data) !== undefined
      ) {
        if (this.match_data && this.match_data.length !== 0) {
          let data = this.match_data;
          Object.keys(this.dataForm).forEach((key) => {
            if (data[key]) {
              this.dataForm[key] = data[key];
            }
          });
        }

        this.dataForm._id = this.match_data._id;
        this.org_score = this.match_data.rule.rule;
        this.dataForm.rule = this.match_data.rule.rule;
        this.dataForm.link_h5 = this.match_data.link_h5;
        this.dataForm.link = this.match_data.link;
      } else {
        this.initData();
      }
    },
    initData() {
      this.dataForm = {
        qr_code: [],
        _id: "",
        type_id: "",
        rule: "",
        rule_url: "",
        link_h5: "",
        link: "",
        match_time: {
          reservationGift_startTime: "",
          reservationGift_endTime: "",
          sign_up_start: "",
          sign_up_end: "",
          revive_startTime: "",
          revive_endTime: "",
          team_startTime: "",
          team_endTime: "",
          pre_startTime: "",
          pre_endTime: "",
          match_start: "",
          match_end: "",
          elimination_time: '',
        },
      };
      //二维码赋值
      if (this.dataForm.type_id === "elimination1") {
        this.dataForm.qr_code = this.elimination1;
      } else if (this.dataForm.type_id === "elimination2") {
        this.dataForm.qr_code = this.elimination2;
      } else if (this.dataForm.type_id === "resurrection") {
        this.dataForm.qr_code = this.resurrection;
      }
    },
    //返回上一次
    goBack() {
      this.$router.push({
        path: `/match/subject/list`,
        query: {
          match_id: this.match_id,
          match_type:this.matchType
        },
      });
      this.removeTabByPath(`/match/subject/match_segment`);
    },
    // 提交
    submitForm() {
      this.$refs.dataForm.validate(async (valid, invalidFields) => {
        console.log('******1',valid);
        if (valid) {
          let d = this.getSaveData();
          delete d.resurrection_rule;
          delete d.resurrection_rule_url;
          delete d.sign_rule;
          delete d.sign_rule_url;
          delete d.team_rule;
          delete d.team_rule_url;
          if (this.org_score !== this.dataForm.rule) {
            let rule_url = await this.uploadContent(
              "score",
              this.dataForm.score
            );
            if (!rule_url || !rule_url.length) {
              this.$message.error("提交失败, 上传规则失败");
              return;
            }
            d.rule_url = rule_url;
          }
          d.season_name = this.optionsMatch[d.type_id];
          d.match_id = this.match_id;

          let { data, code } =
          
            await this.$http.match_subject.save_subject_season(d);
          if (code === 0) {
             this.$message({
            type: "success",
            message: "保存成功",
          });
         this.goBack()
          this.$bus.$emit("onMatchChanged", data);
          }
            
         
        } else {
          console.log("1111", invalidFields); //未校验的项

          let fileds = Object.keys(invalidFields);
          console.log("222", fileds);
          if (fileds.length) {
            let id1 = fileds[0];
            // if (id1 == "desc") {
            //   id1 = "quillEditorQiniuPrize";
            // }
            console.log("333", id1);
            if (document.getElementById(id1)) {
              document
                .getElementById(id1)
                .scrollIntoView({ behavior: "smooth" });
            }

            let refKey = id1.replace(/\./g, "_");
            if (this.$refs[refKey]) {
              this.$refs[refKey].focus();
            }
          }
          return false;
        }
      });
    },
    getSaveData() {
      return {
        ...this.dataForm,
      };
    },
    handleSuccess(group) {
      return (response, file, fileList) => {
        // 根据索引处理上传成功后的逻辑

        this.dataForm.qr_code[group - 1].code_picture = this.imgCode;
        // 其他处理逻辑
      };
    },
    async httpUploadRequest(item) {
      console.log(item);
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.imgCode = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },

    beforeTopImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadEditorRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().notice + fileName;
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        console.log("上传成功");
        // this.$emit('upload',res, file)
        // console.log(res, file);
        //拼接出上传的图片在服务器的完整地址
        let imgUrl = result;
        let type = imgUrl.substring(imgUrl.lastIndexOf(".") + 1);
        console.log(type);
        // 获取富文本组件实例
        let quill = this.$refs.customQuillEditorPrize.quill;
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片||视频  res.info为服务器返回的图片地址
        if (type === "mp4" || type === "MP4") {
          quill.insertEmbed(length, "video", imgUrl);
        } else {
          quill.insertEmbed(length, "image", imgUrl);
        }
        // 调整光标到最后
        quill.setSelection(length + 1);
        //取消上传动画
        this.quillUpdateImg = false;
      } else {
        this.$message.error("上传失败");
      }

      this.loading = false;
    },

    async uploadContent(t, d) {
      let blob = new Blob([d], { type: "text/html;charset=utf-8" });

      // 这么写是因为文件转换是异步任务
      let transToFile = async (blob, fileName, fileType) => {
        return new window.File([blob], fileName, {
          type: fileType,
          charset: "utf-8",
        });
      };

      let fileName = t + "_" + randomStr() + ".html";
      let textContain = transToFile(blob, fileName, "application/json");

      // 转换完成后可以将file对象传给接口
      return await new Promise((resolve, reject) => {
        textContain
          .then(async (res) => {
            let file = res;

            let path =
              uploadOssUrl().notice + this.type + "/" + fileName + fileName;
            let result = await this.$http.uploadFile(path, fileName, file);
            resolve(result);
          })
          .catch(() => {
            resolve(null);
          });
      });
    },
    /**
     *
     * @param item
     * @returns {Promise<void>}
     */
    async httpOfficialWechatUploadRequest(item) {
      this.loading = true;
      let fileName = randomStr() + ".jpg";
      let path = uploadOssUrl().teams + fileName;

      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.dataForm.officialWechatQr = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },

    cancelDialog() {},
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
};
</script>

<style lang="less" scoped>
.editor {
  line-height: normal !important;
  height: fit-content;
  width: 375px;
  padding: 0 !important;
}

/deep/.editor .ql-editor {
  height: 667px;
}

/*/deep/ .el-form-item__label {*/
/*  width: 150px!important;*/
/*}*/

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
/deep/ .el-upload-dragger {
  width: 178px;
  height: 178px;
}
/deep/ .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-upload-dragger {
  text-align: center;
}

/deep/ .el-upload-dragger img {
  margin: auto;
}

/deep/ #quillEditorQiniu .el-form-item__content {
  height: 100%;
}

/deep/ #quillEditorQiniuPrize .el-form-item__content {
  height: 100%;
}

/deep/ .ql-picker-item[data-value="custom-color"] {
  background: transparent !important;
  background-color: transparent !important;
  width: fit-content;
  height: fit-content;
}

/deep/ .ql-picker-item[data-value="custom-color"]::before {
  content: "自定义颜色";
  color: black;
  width: fit-content;
}
.link-tip-wraper {
  display: flex;
}
.link-tip {
  width: 300px;
  margin-left: 10px;
  color: #64676b;
  font-size: 12px;
}
</style>
